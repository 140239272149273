
<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      :max-width="dialogWidth"
      scrollable
    >
      <v-card v-if="item">
        <v-card-title class="pa-0">
          <v-toolbar
          >
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              {{ item.persona.fullname }}
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-list
            two-line
            subheader
          >
            <v-subheader>{{ $t('app.general.information') }}</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="d-flex justify-center">
                  <v-img
                    v-if="item.avatar"
                    :src="item.avatar"
                    :lazy-src="preloader"
                    max-width="350"
                  ></v-img>
                  <template v-else>
                    {{ $t('app.general.undefined') }}
                  </template>
                </v-list-item-title>
                <v-list-item-subtitle>{{ $t('app.headers.avatar') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.persona.fullname }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('app.headers.name') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.email }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('app.headers.email') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    [
                      item.persona.calle,
                      item.persona.colonia,
                      item.persona.localidad.nombre,
                      item.persona.municipio.nombre,
                      item.persona.estado.nombre
                    ].join(', ')
                  }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('app.headers.address') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.persona.telefonos ? item.persona.telefonos.join(', ') : $t('app.general.undefined') }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('app.headers.phones') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.persona.fecha_nacimiento }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $t('app.headers.borndate') }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    name: 'UsuariosDetail',
    data () {
      return {
        dialog: false,
        item: false,
      }
    },
    mounted() {
      EventBus.$on('usuarios-detail', (item) => {
        this.item = item
        this.dialog = true
      })
    },
  }
</script>

<style scoped>

</style>
